import React from 'react'

const Code = () => {
  return (
    <div>
      Haloo to codes!
    </div>
  )
}

export default Code
